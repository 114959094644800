import React, { useContext, useEffect, useRef, useState } from 'react';
import { Map, LayerGroup, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { LayerContext } from "./context/LayerContext";
import AddMarkerButton from "./AddMarkerButton";
import { Icon } from "leaflet";
import * as parkData from "../data/poi.json";
import * as iconData from "../data/iconSet.json";
import {usePosition} from './customHooks/usePosition';

var defaultLatLng = new L.LatLng(53.589888, 10.083405);
// the orientation of the device on app load
var defaultOrientation; 
var defaultZoom = 20;
const circle = L.circle(defaultLatLng, 2);
const circleNext = L.circle(defaultLatLng, 10);

const LeafletMap = () => {
  const { point } = useContext(LayerContext);
  const {latitude, longitude, error} = usePosition();

  const [activePark, setActivePark] = useState(null);
  const [nextTarget, setNextTarget] = useState(null);

  const [direction, setDirection] = useState(null);
  const [distance, setDistance] = useState(null);
  const [heading, setHeading] = useState(null);
//  const [accel, setAccel] = useState(null);
  //const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  

  useEffect(() => {
    if (window.screen.width > window.screen.height) {
      defaultOrientation = "landscape";
    } else {
      defaultOrientation = "portrait";
    }

    window.addEventListener("deviceorientation", onHeadingChange);

    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (map) {
      circle.addTo(map);
      circleNext.addTo(map);
    }// eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(nextTarget ? nextTarget.properties.NAME : "Kein Ziel");
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    const lat2 = nextTarget ? nextTarget.geometry.coordinates[0] : defaultLatLng.lat;
    const lon2 = nextTarget ? nextTarget.geometry.coordinates[1] : defaultLatLng.lng;
    console.log("Target "+lat2 +" - "+ lon2);
    if (map && latitude && longitude) {
      map.setView([latitude, longitude], map.getZoom());
      circle.setLatLng([latitude, longitude], 2);
      circleNext.setLatLng([lat2, lon2], 10);
    }

     if (latitude && longitude) {
      const lat1 = latitude;
      const lon1 = longitude;

      const R = 6371e3; // metres
      const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
      const φ2 = lat2 * Math.PI / 180;
      const Δφ = (lat2 - lat1) * Math.PI / 180;
      const Δλ = (lon2 - lon1) * Math.PI / 180;

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const d = R * c; // in metres
      //console.log("Entfernung: "+d);
      setDistance(d);
 
      // compute direction

      const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
      const x = Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
      console.log(x + " " + y);
      var direct = (360 - (Math.atan2(y, x) * 180 / Math.PI + 360) % 360);
      setDirection(direct);
     }

  }, [nextTarget, latitude, longitude]);

  const mapRef = useRef();

  // browser agnostic orientation
  const getBrowserOrientation = () => {
    var orientation;
    if (window.screen.orientation && window.screen.orientation.type) {
      orientation = window.screen.orientation.type;
    } else {
      orientation = window.screen.orientation ||
        window.screen.mozOrientation ||
        window.screen.msOrientation;
    }

    /*
      'portait-primary':      for (screen width < screen height, e.g. phone, phablet, small tablet)
                                device is in 'normal' orientation
                              for (screen width > screen height, e.g. large tablet, laptop)
                                device has been turned 90deg clockwise from normal
      'portait-secondary':    for (screen width < screen height)
                                device has been turned 180deg from normal
                              for (screen width > screen height)
                                device has been turned 90deg anti-clockwise (or 270deg clockwise) from normal
      'landscape-primary':    for (screen width < screen height)
                                device has been turned 90deg clockwise from normal
                              for (screen width > screen height)
                                device is in 'normal' orientation
      'landscape-secondary':  for (screen width < screen height)
                                device has been turned 90deg anti-clockwise (or 270deg clockwise) from normal
                              for (screen width > screen height)
                                device has been turned 180deg from normal
    */

    return orientation;
  }

  // called on device orientation change
  const onHeadingChange = (event) => {
    var currentHeading = event.alpha;
    

    if (typeof event.webkitCompassHeading !== "undefined") {
      currentHeading = event.webkitCompassHeading; //iOS non-standard
    }

    var orientation = getBrowserOrientation();

    if (typeof currentHeading !== "undefined" && currentHeading !== null) { // && typeof orientation !== "undefined") {
      // we have a browser that reports device heading and orientation


      //   if (debug) {
      //     debugOrientation.textContent = orientation;
      //   }


      // what adjustment we have to add to rotation to allow for current device orientation
      var adjustment = 0;
      if (defaultOrientation === "landscape") {
        adjustment -= 90;
      }

      if (typeof orientation !== "undefined") {
        var currentOrientation = orientation.split("-");

        if (defaultOrientation !== currentOrientation[0]) {
          if (defaultOrientation === "landscape") {
            adjustment -= 270;
          } else {
            adjustment -= 90;
          }
        }

        if (currentOrientation[1] === "secondary") {
          adjustment -= 180;
        }
      }
      
      var computedHeading = currentHeading + adjustment;
      var phase = computedHeading < 0 ? 360 + computedHeading : computedHeading;
      computedHeading = (360 - phase | 0);

      setHeading(computedHeading);

      // apply rotation to compass rose
      /* if (typeof rose.style.transform !== "undefined") {
         rose.style.transform = "rotateZ(" + positionCurrent.hng + "deg)";
       } else if (typeof rose.style.webkitTransform !== "undefined") {
         rose.style.webkitTransform = "rotateZ(" + positionCurrent.hng + "deg)";
       }
     } else {
       // device can't show heading
 
       positionHng.textContent = "n/a";
       showHeadingWarning();
     */

    }
  }

  /* function showHeadingWarning() {
     if (!warningHeadingShown) {
       popupOpen("noorientation");
       warningHeadingShown = true;
     }
   }*/

  function ShowInformation(props) {
    return <div>
      <p>Ziel: {nextTarget ? nextTarget.properties.NAME : "Kein Ziel"} Richtung: {Math.round(direction)}° Entfernung: {Math.round(distance * 100) / 100}m </p>
      <p>Blickrichtung: {Math.round(heading)}° Gehe in Richtung: {Math.round(Math.abs(direction - heading))}°</p>
    </div>
  }

  let iconSet = [];
  iconData.features.map(currentIcon => (
    iconSet.push(
      { 
        id: currentIcon.properties.ICON_ID, 
        icon: new Icon({ iconUrl: currentIcon.properties.URL, iconSize: [currentIcon.properties.SIZE[0], currentIcon.properties.SIZE[1]]}) 
      }
    )
  ));

  return (<div>
    <ShowInformation />
    <Map id="mapId" ref={mapRef}
      center={defaultLatLng}
      zoom={defaultZoom}
    >
      <AddMarkerButton />
      <LayerGroup>
        {point}
      </LayerGroup>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors">
      </TileLayer>
      {parkData.features.map(park => (
        <Marker
          key={park.properties.PARK_ID}
          position={[
            park.geometry.coordinates[0],
            park.geometry.coordinates[1]
          ]}
          onClick={() => {
            setActivePark(park);
            const selectedPark = park;
            setNextTarget(selectedPark);
          }}
          icon={iconSet[park.properties.ICON].icon}
        />

      ))}
      {activePark && (
        <Popup
          position={[
            activePark.geometry.coordinates[0],
            activePark.geometry.coordinates[1]
          ]}
          onClose={() => {
            setActivePark(null);
          }}
        >
          <div>
            <h2>{activePark.properties.NAME}</h2>
            <p>{activePark.properties.DESCRIPTIO}</p>
          </div>
        </Popup>
      )}

    </Map>
  </div>
  )
}

export default LeafletMap;