import React from 'react';
import LeafletMap from './LeafletMap';
import {LayerContextProvider} from "./context/LayerContext";

const App = () => {
   return (
    <LayerContextProvider>
      <LeafletMap />
    </LayerContextProvider>
   )
   }

   export default App;