import React from 'react';
import useAddMarker from './customHooks/useAddMarker';


const AddMarkerButton = (props) => {
    const { setActivate, activate } = useAddMarker(false);

    return <button onClick={() => setActivate(!activate)}>Add Points</button>
}

export default AddMarkerButton;