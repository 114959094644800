import React, { useState } from 'react';

const LayerContext = React.createContext({});

// under the declaration of the LayerContext, declare the Provider Component

const LayerContextProvider = ({ children }) => {
    const [point, setPoint] = useState([0, 0]);

    const defaultValue = {
        point,
        setPoint
    }

    return (
        <LayerContext.Provider value={defaultValue}>
            {children}
        </LayerContext.Provider>
    )
}

// and export both objects
export { LayerContext, LayerContextProvider };